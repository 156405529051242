
import { IonContent, IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';

import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default defineComponent({
  name: 'Cookies',
  components: {
    IonContent,
    IonPage,
    Header,
    Footer,
  }
});
